import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="preload-img"
export default class extends Controller {
  static values = {
    src: String,
    media: String
  }
  
  connect() {
    if (this.hasMediaValue) {
      if (window.matchMedia(this.mediaValue).matches) {
        this.preloadImg()
      }
    } else {
      this.preloadImg()
    }
  }

  preloadImg() {
    let img = document.createElement('img')
    img.src = this.srcValue
  }
}
