import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll"
export default class extends Controller {
  static values = {
    toEnd: Boolean,
  }
  static targets = ["left", "right", "container"]
  connect() {
    // console.log(this.element.scrollLeft)
    if (this.toEndValue) {
      this.element.scrollLeft += 1000000
    }
    this.toggleBtn()
  }

  toggleBtn() {
    if (!this.hasRightTarget || !this.hasLeftTarget) {
      return false
    }
    let leftBuffer = this.containerScrollLeftMax * 0.1
    let rightBuffer = this.containerScrollLeftMax * 0.9
    // console.log(this.containerScrollLeftMax)
    // console.log(leftBuffer)
    // console.log(rightBuffer)
    if (this.containerTarget.scrollLeft > leftBuffer) {
      this.leftTarget.style.visibility = 'visible'
    } else {
      this.leftTarget.style.visibility = 'hidden'
    }

    if (this.containerTarget.scrollLeft > rightBuffer) {
      this.rightTarget.style.visibility = 'hidden'
    } else {
      this.rightTarget.style.visibility = 'visible'
    }
    // console.log('====================')
  }

  goRight() {
    this.go('right', 10, this.rwdDistance, 20)
  }

  goLeft() {
    this.go('left', 10, this.rwdDistance, 20)
  }

  get containerScrollLeftMax() {
    return this.containerTarget.scrollWidth - this.containerTarget.offsetWidth
  }

  get rwdDistance() {
    if (this.vw < 991) {
      return this.vw * 0.5
    } else {
      return this.vw * 0.33
    }
  }

  get vw() {
    return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  }

  go(direction, speed, distance, step) {
    let scrollAmount = 0;
    let container = this.containerTarget
    var slideTimer = setInterval(function(){
        if(direction == 'left'){
            container.scrollLeft -= step;
        } else {
            container.scrollLeft += step;
        }
        scrollAmount += step;
        if(scrollAmount >= distance){
            window.clearInterval(slideTimer);
        }
    }, speed);
  }
}
