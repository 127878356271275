import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="check-to"
export default class extends Controller {
    static targets = ["spot"]

    connect() {
        // console.log(this.element)
    }

    fade(e) {
        // console.log(e.target) // the checkbox itself
        if (e.target.checked) {
            this.spotTarget.style.opacity = 0.3 // the target we want to fade
        } else {
            this.spotTarget.style.opacity = 1
        }
    }
}