import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = ["progress", "current"]

  connect() {
    this.progressTarget.classList.add('enable')
    // console.log("connect", this.element)
    new bootstrap.Carousel(this.element, {
      interval: 4000,
      pause: false,
      ride: "carousel",
      wrap: true
    })
    bootstrap.Carousel.getInstance(this.element).cycle()
  }

  disconnect() {
    // console.log("dispose", bootstrap.Carousel.getInstance(this.element))
    bootstrap.Carousel.getInstance(this.element).dispose
  }

  reset(e) {
    this.progressTarget.classList.remove('enable')
    setTimeout(() => this.progressTarget.classList.add('enable'), 0)
  }

  updateCurrent(e) {
    this.currentTarget.innerText = parseInt(e.to) + 1
  }
}
