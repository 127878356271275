import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact-form-helper"
export default class extends Controller {
  static values = {
    successHintText: { type: String, default: '已收到您的資訊！' },
    errorHintText: { type: String, default: '發生錯誤，請稍後再試一次' }
  }

  connect() {
    const telField = document.getElementById('telephone')
    const phoneField = document.getElementById('mobile_phone')
    telField.addEventListener('input', function(e) {
      telField.setCustomValidity('')
      phoneField.setCustomValidity('')
      // console.log(telField.validity.valid)
      if (telField.validity.valid) {
        phoneField.required = false
        phoneField.removeAttribute('oninvalid')
        phoneField.removeAttribute('oninput')
      }
    })
    phoneField.addEventListener('input', function(e) {
      telField.setCustomValidity('')
      phoneField.setCustomValidity('')
      // console.log(phoneField.validity.valid)
      if (phoneField.validity.valid) {
        telField.required = false
        telField.removeAttribute('oninvalid')
        telField.removeAttribute('oninput')
      }
    })
  }

  showSuccessHint(e) {
    console.log(e.detail)
    if (e.detail.success) {
      this.element.querySelector('.btn-submit .show-when-enabled').innerText = this.successHintTextValue
    } else {
      this.element.querySelector('.btn-submit .show-when-enabled').innerText = this.errorHintTextValue
    }
  }
}
