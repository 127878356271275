import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input"];

    connect() {
        console.log('auto-grow-textarea controller connected...');
        var controller = this;

        this.element.style.resize = 'none';
        this.element.style.minHeight = `${this.element.scrollHeight}px`;
        this.element.style.overflow = 'hidden';

        controller.element.addEventListener('input', controller.resize.bind(controller))
    }

    resize(){
        this.element.style.height = '5px';
        this.element.style.height = `${this.element.scrollHeight}px`;
    }

}