import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="indication"
export default class extends Controller {
  toggleIndicator(e) {
    let brandId = e.target.getAttribute("data-brand-id")
    let activeIndicatorIndex = this.getIndex(e.target)
    for (let indicator of document.querySelectorAll(`.small-indicators[data-brand-id='${brandId}'] .small-indicator`)) {
      indicator.classList.toggle("small-indicator--filled", this.getIndex(indicator) == activeIndicatorIndex)
      // console.log(this.getIndex(indicator))
      // console.log(activeIndicatorIndex)
    }
  }

  getIndex(el) {
    return el.getAttribute("data-indicator-index")
  }
}
