import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["option", "opener"]
  static values = {
    selected: String
  }
  connect() {
    // console.log(this.element)
    // console.log(this.optionTargets)
  }

  selectedValueChanged() {
    if (this.selectedValue) {
      this.optionTargets.forEach((el) => {
        // console.log(el.getAttribute('data-id'))
        // console.log(this.selectedValue)
        if (el.getAttribute('data-id') == this.selectedValue) {
          el.hidden = true
          this.openerTarget.innerText = el.getAttribute('data-label')
        } else {
          el.hidden = false
        }
      })
    }
  }

  setSelected(e) {
    this.selectedValue = e.currentTarget.getAttribute('data-id')
  }
}
